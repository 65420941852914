
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import ViewModel from '@/models/ViewModel'
import { Component, Ref, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import Order from '@/models/Order'
import LabelPicker from '@/components/LabelPicker/LabelPicker.vue'
import OrderForm from './components/OrderForm.vue'

@Component({
  components: {
    CompanyPicker,
    FormInput,
    Widget,
    FormWizard,
    TabContent,
    UserPicker,
    SelectPicker,
    FooterNav,
    LabelPicker,
    OrderForm,
  },
})
export default class OrderEdit extends ViewModel {
  @Ref() readonly formWizard!: any

  @Ref() readonly orderForm!: any

  public modal_open: boolean = false

  public busy: boolean = true

  public step: number = 1

  public isEditing: boolean = false

  public order: Order | any = new Order()

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }
  }

  public onSubmit() {
    this.loading = true
    // this.$refs.formWizard.nextTab()
    this.order.save().then(response => {
      // console.log('created orders', response)
      if (response.status === 200) {
        this.$router.push({ name: 'orders' })
      }
      this.loading = false
    })
  }

  public mounted() {
    if (this.$route.params.id) {
      this.isEditing = true
      Order.find(this.$route.params.id).then(response => {
        this.order = response
        this.busy = false
      })
    } else {
      this.busy = false
    }
  }
}
